export const FOOTER_DATA = {
  footerItems: [
    {
      description: null,
      link: {
        label: "10xgenomics.com",
        target: "_blank",
        url: "/",
      },
      title: "Learn more about 10x Genomics technologies and products",
    },
    {
      description: "Contact us at",
      link: {
        label: "support@10xgenomics.com",
        target: "_self",
        url: "mailto:support@10xgenomics.com",
      },
      title: "Still need help?",
    },
  ],
  form: {
    name: "Support Footer",
    steps: [
      {
        description: "Your email address",
        marketoFormID: null,
        submitLabel: "Subscribe",
        title: "Sign up for support updates",
      },
    ],
  },
  linkSections: [
    {
      label: "Resources",
      links: [
        {
          label: "Find User Guides",
          target: "_self",
          url: "/support/user-guides",
        },
        {
          label: "Publications",
          target: "_self",
          url: "/publications",
        },
        {
          label: "Datasets",
          target: "_self",
          url: "/datasets",
        },
        {
          label: "Videos",
          target: "_self",
          url: "/videos",
        },
        {
          label: "Q&A",
          target: "_blank",
          url: "https://kb.10xgenomics.com/hc/en-us",
        },
        {
          label: "Customer Developed Protocols",
          target: "_self",
          url: "/customer-developed-protocols",
        },
        {
          label: "Analysis Guides",
          target: "_self",
          url: "/analysis-guides",
        },
        {
          label: "10x Genomics Citations",
          target: "_self",
          url: "https://support.10xgenomics.com/docs/citations",
        },
        {
          label: "Compatible Products",
          target: "_self",
          url: "/compatible-products",
        },
      ],
    },
    {
      label: "Community",
      links: [
        {
          label: "Blog",
          target: "_self",
          url: "/blog",
        },
        {
          label: "Events",
          target: "_self",
          url: "/events",
        },
        {
          label: "Podcast",
          target: "_blank",
          url: "https://pages.10xgenomics.com/cell-intel",
        },
      ],
    },
    {
      label: "Legal Notices",
      links: [
        {
          label: "Privacy Policy",
          target: "_self",
          url: "/legal/privacy-policy",
        },
        {
          label: "Terms of Use",
          target: "_self",
          url: "/legal/terms-of-use",
        },
        {
          label: "Email Preferences",
          target: "_self",
          url: "https://pages.10xgenomics.com/subscription.html",
        },
        {
          label: "Other Legal Notices",
          target: "_self",
          url: "/legal/legal-notices",
        },
      ],
    },
  ],
  socialLinks: {
    label: "Follow us on social media",
    links: [
      {
        label: "linkedin",
        target: "_blank",
        url: "https://www.linkedin.com/company/10xgenomics",
      },
      {
        label: "twitter",
        target: "_blank",
        url: "https://twitter.com/10xgenomics",
      },
      {
        label: "youtube",
        target: "_blank",
        url: "https://www.youtube.com/channel/UCx3Dfre5IutNPuLS4ufC9mw",
      },
      {
        label: "facebook",
        target: "_blank",
        url: "https://www.facebook.com/10xGenomics/",
      },
    ],
  },
};
