import { mediaDesktop, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Text from "../../Text";
import type { InstrumentName } from "../constants";
import { INSTRUMENT_NAME_TO_INSTRUMENT_NAME_FOR_SUPPORT } from "../constants";
import ProductCard from "../ProductCard";
import type { InstrumentWithLink } from "./types";

interface ProductCategoryProps {
  children: ReactNode;
  color: string;
}

interface Props {
  className?: string;
  color: string;
  instruments: InstrumentWithLink[];
  title: string;
}

const InstrumentCategory: FunctionComponent<ProductCategoryProps> = ({
  color,
  children,
}) => {
  return (
    <Text
      as={"div"}
      color={"steelMedium"}
      css={css`
        border-right: 4px solid ${color};
        margin-right: 96px;
        width: 148px;
        @media (max-width: ${mediaDesktop}) {
          border: none;
          margin-bottom: 1rem;
        }
      `}
      size={"medium"}
      weight={"semibold"}
    >
      {children}
    </Text>
  );
};

const InstrumentSelectCategory: FunctionComponent<Props> = ({
  title,
  instruments,
  color,
  className,
}) => {
  return (
    <div
      className={className}
      css={css`
        display: flex;
        margin-bottom: 3rem;
        width: 100%;
        :last-of-type {
          margin-bottom: 0px;
        }
        @media (max-width: ${mediaDesktop}) {
          flex-direction: column;
        }
      `}
    >
      <InstrumentCategory color={color}>{title}</InstrumentCategory>
      <ul
        className={"InstrumentItems"}
        css={css`
          column-gap: 116px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          list-style: none;
          margin: 0;
          max-width: 956px;
          padding: 0;
          row-gap: 24px;
          @media (max-width: ${mediaTabletLandscape}) {
            column-gap: 0px;
            grid-template-columns: repeat(1, 100%);
            row-gap: 1rem;
          }
        `}
      >
        {instruments.map((instrument) => {
          const name =
            INSTRUMENT_NAME_TO_INSTRUMENT_NAME_FOR_SUPPORT[
              instrument.name as InstrumentName
            ];

          return instrument.shortDescription ? (
            <li key={instrument.slug}>
              <ProductCard
                image={{ alt: name, src: instrument.supportImage, width: 140 }}
                link={instrument.link}
                subtext={instrument.shortDescription}
                title={name}
              />
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

export default InstrumentSelectCategory;
