import {
  colorBlueDark,
  colorSteelDarkest,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Anchor from "../../Anchor";
import Image from "../../Image";
import Text from "../../Text";

interface Props {
  className?: string;
  image?: {
    alt: string;
    src: string | null;
    width?: number | null;
  };
  labels?: ReactNode;
  link: string;
  subtext: string;
  target?: string;
  title: string;
}

const productCardCss = css`
  align-items: flex-start;
  color: ${colorSteelDarkest};
  display: flex;
  gap: 2rem;
  max-width: 562px;
  text-decoration: none;
  :hover {
    color: ${colorBlueDark};
  }
`;

const ProductCard: FunctionComponent<Props> = ({
  className = "",
  image,
  labels,
  link,
  subtext,
  title,
  target = "_self",
}) => {
  return (
    <Anchor
      className={className}
      css={productCardCss}
      href={link}
      target={target}
    >
      {image && image.src ? (
        <Image
          alt={image.alt}
          css={css`
            max-width: ${image.width || 172}px;
            @media (max-width: ${mediaTabletLandscape}) {
              display: none;
            }
          `}
          src={image.src}
        />
      ) : null}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        <Text as={"div"} color={"inherit"} size={"large"} weight={"semibold"}>
          {title}
        </Text>

        <Text
          as={"div"}
          color={"steelDarker"}
          size={"small"}
          weight={"regular"}
        >
          {subtext}
        </Text>
        {labels}
      </div>
    </Anchor>
  );
};

export default ProductCard;
