import { mediaDesktop, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Text from "../../Text";
import ProductCard from "../ProductCard";
import type { ProductWithLink } from "./types";

interface ProductCategoryProps {
  children: ReactNode;
  color: string;
}

interface Props {
  className?: string;
  color: string;
  products: ProductWithLink[];
  title: string;
}

const ProductCategory: FunctionComponent<ProductCategoryProps> = ({
  color,
  children,
}) => {
  return (
    <Text
      as={"div"}
      color={"steelMedium"}
      css={css`
        border-right: 4px solid ${color};
        margin-right: 96px;
        width: 148px;
        @media (max-width: ${mediaDesktop}) {
          border: none;
          margin-bottom: 1rem;
        }
      `}
      size={"medium"}
      weight={"semibold"}
    >
      {children}
    </Text>
  );
};

const ProductSelectCategory: FunctionComponent<Props> = ({
  title,
  products,
  color,
  className,
}) => {
  return (
    <div
      className={className}
      css={css`
        display: flex;
        margin-bottom: 3rem;
        width: 100%;
        :last-of-type {
          margin-bottom: 0px;
        }
        @media (max-width: ${mediaDesktop}) {
          flex-direction: column;
        }
      `}
    >
      <ProductCategory color={color}>{title}</ProductCategory>
      <ul
        className={"ProductItems"}
        css={css`
          column-gap: 116px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          list-style: none;
          margin: 0;
          padding: 0;
          row-gap: 24px;
          @media (max-width: ${mediaTabletLandscape}) {
            column-gap: 0px;
            grid-template-columns: repeat(1, 100%);
            row-gap: 1rem;
          }
        `}
      >
        {products.map((product) => {
          return (
            product.name &&
            product.shortDescription && (
              <li key={product.slug}>
                <ProductCard
                  css={css`
                    max-width: 420px;
                    width: 100%;
                    @media (max-width: ${mediaTabletLandscape}) {
                      max-width: none;
                    }
                  `}
                  link={product.link}
                  subtext={product.shortDescription}
                  title={product.name}
                />
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

export default ProductSelectCategory;
