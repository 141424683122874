import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { forwardRef } from "react";

import Text from "../../Text";

interface Props {
  children: ReactNode;
}

const ProductSelect = forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  return (
    <div
      css={css`
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        padding: 0 1.5rem 4rem;
        width: 100%;
      `}
      ref={ref}
    >
      <div
        css={css`
          max-width: 1200px;
          width: 100%;
        `}
      >
        <Text
          as={"div"}
          color={"steelDarkest"}
          css={css`
            margin-bottom: 3rem;
          `}
          size={"xlarge"}
          weight={"semibold"}
        >
          {"Product support"}
        </Text>
        {children}
      </div>
    </div>
  );
});

ProductSelect.displayName = "ProductSelect";

export default ProductSelect;
