import { colorGrayLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { forwardRef } from "react";

import Text from "../../Text";

interface Props {
  children: ReactNode;
}

const InstrumentSelect = forwardRef<HTMLDivElement, Props>(
  ({ children }, ref) => {
    return (
      <div
        css={css`
          background: ${colorGrayLightest};
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          padding: 4rem 1.5rem;
          width: 100%;
        `}
        ref={ref}
      >
        <div
          css={css`
            max-width: 1200px;
            width: 100%;
          `}
        >
          <Text
            as={"div"}
            color={"steelDarkest"}
            css={css`
              margin-bottom: 3rem;
            `}
            size={"xlarge"}
            weight={"semibold"}
          >
            {"Instrument support"}
          </Text>
          {children}
        </div>
      </div>
    );
  },
);

InstrumentSelect.displayName = "InstrumentSelect";

export default InstrumentSelect;
